var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',[_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('organizations.step_1.organization_event_title') + ' *'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {rules: [
                  {required: true, message: _vm.$t('messages.requiredField')},
                  {min: 2, message: _vm.$t('messages.minLength') + 2},
                  {max: 25, message: _vm.$t('messages.maxLength') + 25}]
              }
            ]),expression:"[\n              'name',\n              {rules: [\n                  {required: true, message: $t('messages.requiredField')},\n                  {min: 2, message: $t('messages.minLength') + 2},\n                  {max: 25, message: $t('messages.maxLength') + 25}]\n              }\n            ]"}],attrs:{"size":"large","placeholder":_vm.$t('organizations.step_1.enter_organization_name')}})],1),_c('a-form-item',{attrs:{"label":"Email *"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {rules: [
                {type: 'email', message: _vm.$t('messages.emailIsInvalid')},
                {required: true, message: _vm.$t('messages.requiredField')},
                {asyncValidator: _vm.validateEmail}
              ]}
            ]),expression:"[\n              'email',\n              {rules: [\n                {type: 'email', message: $t('messages.emailIsInvalid')},\n                {required: true, message: $t('messages.requiredField')},\n                {asyncValidator: validateEmail}\n              ]}\n            ]"}],attrs:{"size":"large","type":"email","placeholder":"example@email.com"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('basics.phone_number') + ' *'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              {rules: [
                  {required: true, message: _vm.$t('messages.requiredField')},
                  {max: 255, message: _vm.$t('messages.maxLength') + 255},
                  {min: 2, message: _vm.$t('messages.minLength') + 2},
                  {pattern: new RegExp(/\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})?/), message: _vm.$t('messages.invalidPhoneNumber') }]
              }
            ]),expression:"[\n              'phone',\n              {rules: [\n                  {required: true, message: $t('messages.requiredField')},\n                  {max: 255, message: $t('messages.maxLength') + 255},\n                  {min: 2, message: $t('messages.minLength') + 2},\n                  {pattern: new RegExp(/\\(?\\+[0-9]{1,3}\\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})?/), message: $t('messages.invalidPhoneNumber') }]\n              }\n            ]"}],attrs:{"size":"large","placeholder":_vm.$t('organizations.step_1.phone_number_placeholder')}})],1),_c('a-form-item',{staticClass:"location-input",attrs:{"label":_vm.$t('organizations.step_1.location') + ' *'}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'location',
            {rules: [
              {required: true, message: _vm.$t('messages.requiredField')}]
            }
          ]),expression:"[\n              'location',\n            {rules: [\n              {required: true, message: $t('messages.requiredField')}]\n            }\n          ]"}],attrs:{"show-search":"","default-active-first-option":false,"show-arrow":false,"filter-option":false,"labelInValue":true,"not-found-content":null,"size":"large","placeholder":_vm.$t('organizations.step_1.country_city_address')},on:{"search":_vm.searchLocation,"select":_vm.setLocation,"blur":function($event){_vm.addressResultsEmpty = false}}},_vm._l((_vm.addressResults),function(item){return _c('a-select-option',{key:item.x,attrs:{"value":((item.x) + "|" + (item.y) + "|" + (item.label))}},[_vm._v(" "+_vm._s(item.label ? item.label :item)+" ")])}),1),(_vm.addressResultsEmpty)?_c('p',{staticClass:"location-error"},[_vm._v(_vm._s(_vm.$t('organizations.step_1.invalid_location')))]):_vm._e()],1),_c('p',{staticClass:"ant-col ant-form-item-label custom-lable"},[_vm._v(_vm._s(_vm.$t('organizations.step_1.coordinates') + ' *'))]),_c('div',{staticClass:"coordinates-wrapper"},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'latitude',
            {rules: [ {required: true, message: _vm.$t('messages.requiredField')} ]
            }
          ]),expression:"[\n            'latitude',\n            {rules: [ {required: true, message: $t('messages.requiredField')} ]\n            }\n          ]"}],attrs:{"type":"number","size":"large","placeholder":"2640 48 224"},on:{"change":_vm.setLatitude}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'longitude',
            {rules: [ {required: true, message: _vm.$t('messages.requiredField')} ]
            }
          ]),expression:"[\n            'longitude',\n            {rules: [ {required: true, message: $t('messages.requiredField')} ]\n            }\n          ]"}],attrs:{"type":"number","size":"large","placeholder":"2640 48 224"},on:{"change":_vm.setLongitude}})],1)],1),(_vm.showMap)?_c('p',{staticClass:"ant-col ant-form-item-label custom-lable"},[_vm._v(_vm._s(_vm.$t('organizations.step_1.pick_on_map')))]):_vm._e(),_c('LocationMap',{ref:"locationMap",attrs:{"show":_vm.showMap,"map":_vm.map,"refer":"locationMap","location":_vm.location},on:{"openMap":_vm.openMap,"handleMapClick":_vm.handleMapClick}})],1),_c('a-col',{staticClass:"second-column",attrs:{"lg":12}},[(_vm.getRole)?_c('a-form-item',{attrs:{"label":_vm.$t('organizations.step_1.link_with_organization')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['linked_organizations']),expression:"['linked_organizations']"}],attrs:{"mode":"multiple","size":"large","showArrow":true,"placeholder":_vm.$t('organizations.select_from_list'),"filter-option":false,"not-found-content":_vm.fetching ? undefined : null},on:{"search":_vm.fetchOrganizations,"focus":_vm.fetchOrganizations}},[(_vm.fetching)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.organizationsDropdownList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1):_vm._e(),_c('a-form-item',{attrs:{"label":_vm.$t('organizations.detail_managers_name') + ' *'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'first_name',
              {rules: [
                  {required: true, message: _vm.$t('messages.requiredField')},
                  {min: 2, message: _vm.$t('messages.minLength') + 2},
                  {max: 30, message: _vm.$t('messages.maxLength') + 30}]
              }
            ]),expression:"[\n              'first_name',\n              {rules: [\n                  {required: true, message: $t('messages.requiredField')},\n                  {min: 2, message: $t('messages.minLength') + 2},\n                  {max: 30, message: $t('messages.maxLength') + 30}]\n              }\n            ]"}],attrs:{"size":"large","placeholder":_vm.$t('organizations.step_1.enter_name'),"disabled":!_vm.emailIsValid},on:{"change":_vm.setFirstName}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('organizations.step_1.managers_last_name') + ' *'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'last_name',
              {rules: [
                  {required: true, message: _vm.$t('messages.requiredField')},
                  {min: 2, message: _vm.$t('messages.minLength') + 2},
                  {max: 30, message: _vm.$t('messages.maxLength') + 30}]
              }
            ]),expression:"[\n              'last_name',\n              {rules: [\n                  {required: true, message: $t('messages.requiredField')},\n                  {min: 2, message: $t('messages.minLength') + 2},\n                  {max: 30, message: $t('messages.maxLength') + 30}]\n              }\n            ]"}],attrs:{"size":"large","placeholder":_vm.$t('organizations.step_1.enter_last_name'),"disabled":!_vm.emailIsValid},on:{"change":_vm.setLastName}})],1)],1),_c('a-modal',{attrs:{"width":1016,"wrapClassName":"map-modal","footer":false},on:{"cancel":_vm.closeMap},model:{value:(_vm.showBigMap),callback:function ($$v) {_vm.showBigMap=$$v},expression:"showBigMap"}},[_c('LocationMap',{ref:"bigLocationMap",attrs:{"show":_vm.showMap,"map":_vm.map,"refer":"bigLocationMap","location":_vm.location,"modalMap":true},on:{"handleMapClick":_vm.handleMapClick}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }