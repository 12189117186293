<template>
  <a-row>
    <a-col :lg="12">
      <a-form-item class="user-domains-field" v-if="getRole === 1" :label="$t('organizations.step_1.user_domains')">
        <div class="has-error">
          <p class="custom-explain ant-form-explain">{{ $t('organizations.domains_warning') }}</p>
        </div>
        <a-select
          size="large"
          mode="tags"
          :placeholder="$t('organizations.step_1.domain_names')"
          v-decorator="[
            'user_domains',
            { rules: [{ validator: validateDomains }] }
          ]"
        >
        </a-select>
      </a-form-item>
    </a-col>
    <a-col :lg="12" class="second-column">
      <a-form-item class="user-categories-field" v-if="getRole === 1" :label="$t('organizations.step_1.user_categories')">
        <a-select
          size="large"
          mode="tags"
          :placeholder="$t('organizations.select_from_list')"
          v-decorator="[
            'profiles',
            { rules: [{ validator: validateProfiles }] }
          ]"
        >
        </a-select>
      </a-form-item>
      <a-form-item v-if="false" :label="$t('organizations.step_1.link_to_automatically_store_users')">
        <a-input
            type="text"
            size="large"
            placeholder="http://"
            v-decorator="['file_url', { rules: [{ validator: validateUrl }] }]"
        >
        </a-input>
      </a-form-item>
      <a-form-item v-if="false" :label="$t('organizations.step_1.automation_time')">
        <a-time-picker
            size="large"
            format="HH:mm"
            style="width: 100%"
            :disabled="!isFileUrl"
            v-decorator="['schedule_at']"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { requiredField, minLength, maxLength, domainIsNotValid, emailIsInvalid } from '@/utils/messages';

export default {
  name: 'Step4',
  data () {
    return {
      requiredField,
      minLength,
      maxLength,
      domainIsNotValid,
      emailIsInvalid,
      isFileUrl: false
    }
  },
  mounted() {
    let modal = document.getElementsByClassName('create-organization-manually')[0];
    if (modal) {
      modal.addEventListener('scroll', this.handleScroll);
    }

    this.$emit('setStateStep');
  },
  computed: {
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    }
  },
  methods: {
    handleScroll () {
      let openedSelects = document.getElementsByClassName('ant-select ant-select-open');
      let selectDropdowns = document.getElementsByClassName('ant-select-dropdown');

      openedSelects.forEach(element => {
        if (element) {
          element.classList.remove(...['ant-select-open', 'ant-select-focused']);
        }
      })

      selectDropdowns.forEach(element => {
        if (element) {
          element.style.display = 'none';
        }
      })
    },
    validateDomains(rule, value, callback) {
      if(Boolean(value)){
        try {
          const reg = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/;
          const restrictedDomains = [
            'gmail.com',
            'outlook.com',
            'mail.com',
            'yahoo.com',
            'aol.com',
            'lycos.com'
          ]
          value.forEach(domain => {
            if (!reg.test(domain)) {
              throw new Error(this.$t('messages.domainIsNotValid'));
            }
            if(restrictedDomains.includes(domain)){
              throw new Error(this.$t('messages.domainIsRestricted'));
            }
          })
        } catch (error) {
          callback(error);
          return
        }
      }
      callback()
    },
    validateProfiles(rule, value, callback) {
      if (Boolean(value)) {
        try {
          value.forEach(profile => {
            if (profile.length > 12) {
              throw new Error(this.$t('messages.profileIsTooLong'));
            }
          })
          if (value.length > 5) {
            throw new Error(this.$t('messages.profileIsTooLong'));
          }
        } catch (error) {
          callback(error);
          return
        }
      }
      callback()
    },
    validateUrl(rule, value, callback) {
      if (value) {
        try {
          new URL(value);
          this.isFileUrl = true;
        } catch (error) {
          this.isFileUrl = false;
          callback(new Error(this.$t('messages.invalidUrl')));
        }
      }

      callback();
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}

</script>
