<template>
  <div class="steps-wrapp">
    <div class="steps">
      <div class="steps-header">
        <div class="steps-description">
          <h2 class="steps-title">{{stepTitle}}</h2>
          <div class="steps-description">{{stepDescription}}</div>
        </div>
        <div v-if="!isSuccessfulCreate" class="steps-numeration">{{$t('basics.step') + ' '}} {{getRole === 1 ? currentStep + 1 : (currentStep == 0 ? currentStep + 1 : currentStep)}} {{$t('basics.from')}} {{getRole === 1 ? 4 : 3}}</div>
      </div>
      <div class="steps-nav">
        <a-steps :current="getRole === 1 ? currentStep : (currentStep !== 0 ? currentStep - 1 : currentStep)">
          <a-step :description="$t('basics.general')" class="first-step" />
          <a-step v-if="this.getRole === 1" :description="$t('basics.users')" />
          <a-step :description="$t('basics.interaction')" />
          <a-step :description="$t('basics.parameters')" />
        </a-steps>
        <div class="steps-line" />
      </div>
    </div>
    <div class="steps-content">
      <a-form
          v-if="!isSuccessfulCreate"
          class="create-organization-form"
          :form="form">
        <step1 v-if="currentStep === 0" @setStateStep="setStepState(firstStepState)"/>
        <step4 v-if="currentStep === 1" @setStateStep="setStepState(fourthStepState)"/>
        <step2 v-if="currentStep === 2" :currentForm="form" :firstStepState="firstStepState" @setStateStep="setStepState(secondStepState)"/>
        <step3 v-if="currentStep === 3" @setStateStep="setStepState(thirdStepState)"/>
      </a-form>

      <div class="successful-step" v-if="isSuccessfulCreate">
        <div>
          <h3 class="steps-title">{{$t('basics.hi') + ' ' + firstStepState.first_name + ' '+ firstStepState.last_name}}</h3>
          <p>{{createSuccessfulText}}</p>
        </div>
      </div>

      <div class="step-actions">
        <a-button type="primary" @click="nextStep" 
        v-if="(getRole !== 1 && (currentStep === 0 || currentStep === 2)) || 
        (getRole === 1 && (currentStep === 0 || currentStep === 1 || currentStep === 2))">
          {{ $t('basics.next') }}
        </a-button>
        <a-button type="primary"
                  @click="handleSubmit"
                  v-if="currentStep === 3"
                  :loading="loginBtn"
                  :disabled="loginBtn">{{ $t('organizations.create') }}</a-button>
        <a-button type="primary" v-if="isSuccessfulCreate" @click="closeModal">Ok</a-button>
      </div>
    </div>
    <a-modal :width="570" wrapClassName="warning-modal" :footer="false" v-model="showLockModeWarningPopup" @cancel="showLockModeWarningPopup = false">
      <div class="wrapper">
        <p>{{ $t('organizations.step_2.lockModeWarning') }}</p>
        <div class="switch-wrapper">
          <a-switch @change="lockModeChange" v-decorator="[{ initialValue:false }]" />
          <p>{{ $t('organizations.read_agree') }}</p>
        </div>
      </div>
      <div class="btn-wrapper">
        <a-button @click="showLockModeWarningPopup = false">{{ $t('basics.cancel') }}</a-button>
        <a-button class="left-btn ant-btn ant-btn-primary" @click="handleOk" :disabled="!userAgree">Ok</a-button>
      </div>
    </a-modal>
    <!-- USBc Warning popup -->
    <a-modal :width="570" wrapClassName="warning-modal" :footer="false" v-model="showUSBcWarningPopup" @cancel="showUSBcWarningPopup = false">
      <div class="wrapper">
        <p>{{ $t('organizations.step_3.usbcWarning') }}</p>
        <div class="switch-wrapper">
          <a-switch @change="usbcChange" v-decorator="[{ initialValue:false }]" />
          <p>{{ $t('organizations.read_agree') }}</p>
        </div>
      </div>
      <div class="btn-wrapper">
        <a-button @click="showUSBcWarningPopup = false">{{ $t('basics.cancel') }}</a-button>
        <a-button class="left-btn ant-btn ant-btn-primary" @click="submitForm" :disabled="!userAgreeUSBc">Ok</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import {mapGetters} from 'vuex';

export default {
  name: 'CreateOrganizationManually',
  components: {
    Step1,
    Step2,
    Step3,
    Step4
  },
  data() {
    return {
      step: null,
      loginBtn: false,
      isSuccessfulCreate: false,
      form: this.$form.createForm(this),
      stepTitle: this.$t('organizations.create_organization'),
      stepDescription: this.$t('organizations.follow_steps'),
      createSuccessfulText: this.$t('organizations.saved_success'),
      firstStepState: {},
      secondStepState: {},
      thirdStepState: {},
      fourthStepState: {},
      showLockModeWarningPopup: false,
      showUSBcWarningPopup: false,
      userAgree: false,
      userAgreeUSBc: false

    }
  },
  props: {
    currentForm: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    currentStep() {
      return this.$store.getters['createOrganization/getCurrentStep']
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    }
  },
  methods: {
    setStepState(stateArray){
      this.form.setFieldsValue(stateArray);
    },
    saveFieldValue() {
      this.form.validateFields((err, values) => {
        if (this.step === 0) {
          this.firstStepState = {...values}
        } else if(this.step === 1) {
          this.fourthStepState = {...values}
        } else if(this.step === 2) {
          this.secondStepState = {...values}
        } else if(this.step === 3) {
          this.thirdStepState = {...values}
        } else return
      })
    },
    nextStep () {
      if (this.$parent.$parent.$refs.formscontainer) {
        this.$parent.$parent.$refs.formscontainer.scrollTop = 0
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.currentStep == 2) {
            if (values.is_lock_mode == false) {
             this.showLockModeWarningPopup = true;
             return;
            }
          }
          this.step = this.currentStep
          this.saveFieldValue()
          if (this.currentStep === 0 && this.getRole !== 1) {
            this.$store.commit('createOrganization/SET_CURRENT_STEP', this.step +=2)
          } else {
            this.$store.commit('createOrganization/SET_CURRENT_STEP', this.step +=1)
          }
        }
      })
    },
    handleOk() {
      this.showLockModeWarningPopup = false;
      this.step = this.currentStep;
      this.saveFieldValue();
      this.$store.commit('createOrganization/SET_CURRENT_STEP', this.step +=1);
    },
    lockModeChange(e) {
      this.userAgree = e;
    },
    usbcChange(e) {
      this.userAgreeUSBc = e;
    },
    closeModal() {
      if(this.getRole) {
        this.$emit('closeModal')
      } else {
        this.$router.push({ path: '/user/login' })
      }
    },
    async submitForm() {
      this.showUSBcWarningPopup = false;
      const firstStepState = Object.assign({}, this.firstStepState);
      if (firstStepState.latitude && firstStepState.longitude) {
        firstStepState.location.latitude = firstStepState.latitude;
        firstStepState.location.longitude = firstStepState.longitude;
        delete firstStepState.latitude;
        delete firstStepState.longitude;
      }
      const data = {
        ...firstStepState,
        ...this.secondStepState,
        ...this.thirdStepState,
        ...this.fourthStepState
      }

      if (this.getRole) {
        this.$store.dispatch('createOrganization/CreateOrganization' , data)
            .then(() => {
              this.$store.commit('createOrganization/SET_CURRENT_STEP', this.step +=1)
              this.isSuccessfulCreate = true
              this.loginBtn = false
            })
            .catch((error) => {
              this.loginBtn = false
              for (const [key, value] of Object.entries(error.response.data.error.errors)) {
                this.$message.error(`${key}: ${value}`);
              }
            })
      } else {
        this.$store.dispatch('createOrganization/CreateOrganizationsByToken' , {
          token: this.$route.query.token,
          data: {
            ...firstStepState,
            ...this.secondStepState,
            ...this.thirdStepState
          }
        })
            .then(() => {
              this.$store.commit('createOrganization/SET_CURRENT_STEP', this.step +=1)
              this.isSuccessfulCreate = true
              this.loginBtn = false
            })
            .catch((error) => {
              this.loginBtn = false
              for (const [key, value] of Object.entries(error.response.data.error.errors)) {
                this.$message.error(`${key}: ${value}`);
              }
            })
      }
    },
    handleSubmit () {
      this.loginBtn = true
      this.saveFieldValue();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.is_input_usb_c_100w == true) {
           this.loginBtn = false;
           this.showUSBcWarningPopup = true;
           return;
          }
          this.submitForm();
        } else {
          this.loginBtn = false
        }
      })
    }
  }
}
</script>

<style lang="less">
.ant-steps-item-wait,
.ant-steps-item-process {
  .ant-steps-item-icon {
    background-color: #DDE1E5;
    border: none;
    font-size: 0;
  }

  &.first-step .ant-steps-item-icon {
    background-color: #F6CE54;
  }
}

.ant-steps-item-process {
  .ant-steps-item-icon {
    &:before {
      content: '';
      height: 8px;
      width: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      border-radius: 50%;
      background-color: #40454E;
    }
  }
}

.ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: #F6CE54;
    border: none;
  }

  .ant-steps-item-icon > .ant-steps-icon {
    color: #ffffff;
  }

  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: #40454E;
  }
}

.ant-steps-item {
  &-content {
    display: block;
  }

  &-description {
    font-size: 11px;
    margin-top: 10px;
  }

  &-title {
    display: none;
  }

  &-icon {
    z-index: 2;
    position: relative;
  }
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0;
}

.successful-step {
  display: flex;
  text-align: center;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.step-actions {
  text-align: center;
  margin-top: 50px;
}
.location-error {
  color: red;
  margin-top: 5px;
}
.warning-modal {
.ant-modal-body {
  padding: 45px;
}
}
</style>

<style lang="less" scoped>
.steps {
  &-wrapp {
    height: 100%;
  }

  &-nav {
    max-width: 440px;
    position: relative;
  }

  &-title {
    font-size: 22px;
  }

  &-numeration {
    font-size: 11px;
    margin-top: 10px;
    color: rgba(64, 69, 78, 0.6);
    white-space: nowrap;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 28px;
  }

  &-line {
    background: linear-gradient(90deg, #384254 -120%, #ffffff 120%);
    width: calc(100% - 40px);
    height: 1px;
    position: absolute;
    top: 16px;
  }

  &-content {
    max-height: calc(100% - 340px);
    height: 100%;
    min-height: 150px;
  }
}
.warning-modal {
  .ant-modal-body {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    .switch-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      p {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
  .btn-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .left-btn {
      margin-left: 50px;
    }
  }
}
</style>
