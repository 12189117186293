<template>
  <a-row>
    <a-col :lg="12">
      <a-form-item class="one-row large-item lock-mode-field" :label="$t('organizations.step_2.lock_mode')">
        <a-switch @change="lockModeChange" v-decorator="['is_lock_mode',
        {valuePropName: 'checked', initialValue:true }]" />
      </a-form-item>
      <div class="custom-error has-error">
        <p v-if="!lockModeEnabled" class="custom-explain ant-form-explain">{{ $t('organizations.step_2.lockModeWarning') }}</p>
      </div>
      <a-form-item :label="$t('organizations.step_2.number_of_picos')">
        <a-input-number
            size="large"
            type="number"
            :min="picoLimit[0]"
            :max="picoLimit[picoLimit.length - 1]"
            style="width: 55px"
            v-decorator="[
                  'number_limit',
                  {rules: [{required: true, message: $t('messages.requiredField')}], initialValue: picoLimit[0]}
            ]"
        >
        </a-input-number>
      </a-form-item>
      <a-form-item class="one-row large-item" :label="$t('organizations.step_2.limit_holding_time')">
        <a-switch @change="onChange('time_limit.duration')" v-decorator="['is_time_limited',
        {valuePropName: 'checked', initialValue:false }]" />
      </a-form-item>
      <a-form-item class="flex-row" :label="$t('organizations.step_2.holding_time')">
        <a-form-item>
          <a-input-number
              :disabled="!isTimeLimited"
              size="large"
              type="number"
              :min="0"
              style="width: 50px;"
              v-decorator="[
                  'time_limit.duration',
                  {rules: [{required: isTimeLimited, message: $t('messages.requiredField')}]}]"
          >
          </a-input-number>
        </a-form-item>
        <a-form-item>
          <a-select
              :disabled="!isTimeLimited"
              size="large"
              style="width: 55px"
              v-decorator="[`time_limit.unit`, {initialValue: $t('basics.time_units.m')}]">
            <a-select-option v-for="item in Object.values($t('basics.time_units'))" :value="item" :key="item">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form-item>
    </a-col>
    <a-col :lg="12" class="second-column">
      <a-form-item class="one-row large-item" :label="$t('organizations.step_2.limit_working_radius')">
        <a-switch :disabled="!canSwitchAreaToggle" @change="onChange('area_limit.center')" v-decorator="['is_area_limited',
        {valuePropName: 'checked', initialValue:false }]" />
      </a-form-item>
      <a-form-item class="large-item flex-row" :label="$t('organizations.step_2.working_radius')">
        <a-form-item>
          <span class="area-limit-icon">±</span>
          <a-input-number
              :disabled="!isAreaLimited"
              @change="setRadiusValue"
              size="large"
              type="number"
              :min="1"
              style="width: 60px"
              v-decorator="[
                  'area_limit.radius',
                  {rules: [{required: true, message: $t('messages.requiredField')}], initialValue: 1}]"
          >
          </a-input-number>
        </a-form-item>
        <a-form-item>
          <a-select
              :disabled="!isAreaLimited"
              @change="setRadiusUnits"
              size="large"
              style="width: 60px"
              v-decorator="[`area_limit.unit`,
                  {initialValue: metricUnits[0]}
                  ]">
            <a-select-option v-for="item in metricUnits" :value="item" :key="item">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form-item>
      <div class="map-block">
        <p v-if="showMap" class="ant-col step-2 ant-form-item-label custom-lable">{{ $t('organizations.step_1.pick_radius') }}</p>
        <LocationMap :radiusMap="true" :circle="circle" @openMap="openMap" :show="showMap" :map="map" ref="radiusMap" refer="radiusMap" :location="location" />
      </div>
      <a-form-item class="one-row large-item sm-mb" :label="$t('organizations.step_2.gps_tracker')">
        <a-switch @change="gpsChange" v-decorator="['is_gps_on',
        {valuePropName: 'checked', initialValue:false }]" />
      </a-form-item>
      <a-form-item class="one-row large-item sm-mb" :label="$t('organizations.step_2.nfc_reader')">
        <a-switch v-decorator="['is_nfc_reader_on',
        {valuePropName: 'checked', initialValue:true }]" />
      </a-form-item>
      <a-form-item v-if="getRole === 1" class="flex-row large-item" :label="$t('organizations.step_2.logs_delay')" style="margin-top: 38px">
        <a-form-item>
          <a-input-number
              size="large"
              type="number"
              :min="2"
              :max="60"
              style="width: 50px;"
              v-decorator="[
                  'log_delay.duration',
                  {rules: [
                      {required: true, message: $t('messages.requiredField')}],
                  initialValue: 10}]"
          >
          </a-input-number>
        </a-form-item>
        <a-form-item>
          <a-input
              class="not-editable-input"
              size="large"
              style="width: 55px"
              v-decorator="[
                  'log_delay.unit',
                  {rules: [
                      {required: true, message: $t('messages.requiredField')}],
                  initialValue: 's'}]"
             >
          </a-input>
        </a-form-item>
      </a-form-item>
      <a-form-item class="flex-row large-item" v-if="getRole === 1" :label="$t('organizations.step_2.minimum_soc')">
        <a-input-number
            size="large"
            type="number"
            :min="0"
            :max="50"
            v-decorator="[
                'soc_min',
                {rules: [
                    {required: true, message: $t('messages.requiredField')}],
                initialValue: 0}]"
        >
        </a-input-number>
      </a-form-item>
    </a-col>
    <a-modal :width="1016" wrapClassName="map-modal" :footer="false" v-model="showBigMap" @cancel="closeMap">
      <LocationMap :radiusMap="true" :circle="circle" :map="map" ref="bigRadiusMap" refer="bigRadiusMap" :location="location" :modalMap="true" />
    </a-modal>
  </a-row>
</template>

<script>
import { picoLimit, timeUnits, metricUnits } from '@/utils/defaulVeriables';
import { latLng } from "leaflet";
import LocationMap from '@/components/LocationMap/LocationMap.vue';

export default {
  name: 'Step2',
  components: {
    LocationMap
  },
  data () {
    return {
      picoLimit,
      timeUnits,
      metricUnits,
      isTimeLimited: false,
      isAreaLimited: false,
      lockModeEnabled: true, 
      organizationsDropdownList: this.$store.getters['organizations/getOrganizations'],
      fetching: false,
      canSwitchAreaToggle: false,
      map: {
        zoom: 13,
        center: latLng(50.454442264865214, 30.51689219566667),
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        mapOptions: {
          zoomSnap: 0.5,
          zoomControl: false
        },
        latitude: 50.454442264865214,
        longitude: 30.51689219566667
      },
      location: null,
      showBigMap: false,
      circle: null,
    }
  },
  props: ['currentForm', 'firstStepState'],
  mounted() {
    this.$emit('setStateStep');
    this.currentForm.getFieldValue("is_area_limited") ? this.isAreaLimited = true : false;
    this.currentForm.getFieldValue("is_time_limited") ? this.isTimeLimited = this.currentForm.getFieldValue("is_time_limited") : false;
    this.currentForm.getFieldValue("is_lock_mode") == false ? this.lockModeEnabled = false : true;
    this.currentForm.getFieldValue("is_gps_on") == true ? this.canSwitchAreaToggle = true : false;
    this.location = latLng(this.firstStepState.latitude, this.firstStepState.longitude);
  },
  computed: {
    showMap() {
      return this.isAreaLimited && this.currentForm.getFieldValue("is_gps_on") ? true : false;
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    }
  },
  watch: {
    showMap() {
      if (this.showMap) {
        let radius;
        if (this.currentForm.getFieldValue('area_limit').unit == "km") {
          radius = this.currentForm.getFieldValue('area_limit').radius + "000";
        } else {
          radius = this.currentForm.getFieldValue('area_limit').radius;
        }
        this.circle = {
          center: latLng(this.firstStepState.latitude, this.firstStepState.longitude),
          radius: +radius
        };
        this.$nextTick(() => {
          this.$refs.radiusMap.$refs.radiusMap.mapObject.setView([this.firstStepState.latitude, this.firstStepState.longitude], 13); 
        });
      }
    }
  },
  methods: {
    onChange(filed){
      if (filed === 'time_limit.duration') {
        this.isTimeLimited = !this.isTimeLimited
      } else if (filed === 'area_limit.center') {
        this.isAreaLimited = !this.isAreaLimited
      }
      this.$nextTick(() => {
        this.currentForm.validateFields([filed], { force: true });
      });
    },
    gpsChange(e) {
      if(e == false) {
        this.currentForm.setFieldsValue({
          is_area_limited: false
        });
        this.canSwitchAreaToggle = false;
        this.isAreaLimited = false;
      } else {
        this.canSwitchAreaToggle = true;
      }
    },
    setRadiusUnits(e) {
      if (e == "km") {
        const radius = this.currentForm.getFieldValue('area_limit').radius + "000";
        this.circle.radius = +radius;
      } else {
        this.circle.radius = this.currentForm.getFieldValue('area_limit').radius;
      };
    },
    setRadiusValue(e) {
      if (this.currentForm.getFieldValue('area_limit').unit == "km") {
        const radius = e + "000";
        this.circle.radius = +radius;
      } else {
        this.circle.radius = e;
      };
    },
    openMap() {
      this.showBigMap = true;
      if (this.location.lat && this.location.lng) {
        this.$nextTick(() => {
          this.$refs.bigRadiusMap.$refs.bigRadiusMap.mapObject.setView([this.location.lat, this.location.lng], 13); 
        })
      }
    },
    closeMap() {
      this.showBigMap = false;
      if (this.location.lat && this.location.lng) {
        this.$refs.radiusMap.$refs.radiusMap.mapObject.setView([this.location.lat, this.location.lng], 13); 
      }
    },
    lockModeChange(e) {
      this.lockModeEnabled = e;
    }
  }
}
</script>
<style>
.custom-error {
  position: relative;
}
.custom-explain {
  position: absolute;
  top: -75px;
}
.lock-mode-field {
  margin-bottom: 70px;
}
</style>