<template>
  <a-row>
    <a-col :lg="12">
      <a-form-item :label="$t('organizations.step_1.organization_event_title') + ' *'">
        <a-input
            size="large"
            :placeholder="$t('organizations.step_1.enter_organization_name')"
            v-decorator="[
                'name',
                {rules: [
                    {required: true, message: $t('messages.requiredField')},
                    {min: 2, message: $t('messages.minLength') + 2},
                    {max: 25, message: $t('messages.maxLength') + 25}]
                }
              ]"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="Email *">
        <a-input
            size="large"
            type="email"
            placeholder="example@email.com"
            v-decorator="[
                'email',
                {rules: [
                  {type: 'email', message: $t('messages.emailIsInvalid')},
                  {required: true, message: $t('messages.requiredField')},
                  {asyncValidator: validateEmail}
                ]}
              ]"
        >
        </a-input>
      </a-form-item>
      <a-form-item :label="$t('basics.phone_number') + ' *'">
        <a-input
            size="large"
            :placeholder="$t('organizations.step_1.phone_number_placeholder')"
            v-decorator="[
                'phone',
                {rules: [
                    {required: true, message: $t('messages.requiredField')},
                    {max: 255, message: $t('messages.maxLength') + 255},
                    {min: 2, message: $t('messages.minLength') + 2},
                    {pattern: new RegExp(/\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})?/), message: $t('messages.invalidPhoneNumber') }]
                }
              ]"
        >
        </a-input>
      </a-form-item>
      <a-form-item :label="$t('organizations.step_1.location') + ' *'" class="location-input">
        <a-select
            show-search
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :labelInValue="true"
            :not-found-content="null"
            size="large"
            :placeholder="$t('organizations.step_1.country_city_address')"
            @search="searchLocation"
            @select="setLocation"
            @blur="addressResultsEmpty = false"
            v-decorator="[
                'location',
              {rules: [
                {required: true, message: $t('messages.requiredField')}]
              }
            ]"
        >
          <a-select-option v-for="item in addressResults" :value="`${item.x}|${item.y}|${item.label}`" :key="item.x">
            {{item.label ? item.label :item }}
          </a-select-option>
        </a-select>
        <p class="location-error" v-if="addressResultsEmpty">{{ $t('organizations.step_1.invalid_location') }}</p>
      </a-form-item>
      <p class="ant-col ant-form-item-label custom-lable">{{ $t('organizations.step_1.coordinates') + ' *' }}</p>
      <div class="coordinates-wrapper">
        <a-form-item>
          <a-input
            @change="setLatitude"
            type="number"
            size="large"
            placeholder="2640 48 224"
            v-decorator="[
              'latitude',
              {rules: [ {required: true, message: $t('messages.requiredField')} ]
              }
            ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            @change="setLongitude"
            type="number"
            size="large"
            placeholder="2640 48 224"
            v-decorator="[
              'longitude',
              {rules: [ {required: true, message: $t('messages.requiredField')} ]
              }
            ]"
          >
          </a-input>
        </a-form-item>
      </div>
      <p v-if="showMap" class="ant-col ant-form-item-label custom-lable">{{ $t('organizations.step_1.pick_on_map') }}</p>
      <LocationMap :show="showMap" :map="map" @openMap="openMap" ref="locationMap" refer="locationMap" @handleMapClick="handleMapClick" :location="location" />
    </a-col>
    <a-col :lg="12" class="second-column">
      <a-form-item v-if="getRole" :label="$t('organizations.step_1.link_with_organization')">
        <a-select
            mode="multiple"
            size="large"
            :showArrow="true"
            :placeholder="$t('organizations.select_from_list')"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            @search="fetchOrganizations"
            @focus="fetchOrganizations"
            v-decorator="['linked_organizations']"
            >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="item in organizationsDropdownList" :value="item.id" :key="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('organizations.detail_managers_name') + ' *'">
        <a-input
            size="large"
            :placeholder="$t('organizations.step_1.enter_name')"
            :disabled="!emailIsValid"
            @change="setFirstName"
            v-decorator="[
                'first_name',
                {rules: [
                    {required: true, message: $t('messages.requiredField')},
                    {min: 2, message: $t('messages.minLength') + 2},
                    {max: 30, message: $t('messages.maxLength') + 30}]
                }
              ]"
        >
        </a-input>
      </a-form-item>
      <a-form-item :label="$t('organizations.step_1.managers_last_name') + ' *'">
        <a-input
            size="large"
            :placeholder="$t('organizations.step_1.enter_last_name')"
            :disabled="!emailIsValid"
            @change="setLastName"
            v-decorator="[
                'last_name',
                {rules: [
                    {required: true, message: $t('messages.requiredField')},
                    {min: 2, message: $t('messages.minLength') + 2},
                    {max: 30, message: $t('messages.maxLength') + 30}]
                }
              ]"
        >
        </a-input>
      </a-form-item>
    </a-col>
    <a-modal :width="1016" wrapClassName="map-modal" :footer="false" v-model="showBigMap" @cancel="closeMap">
      <LocationMap :show="showMap" :map="map" ref="bigLocationMap" refer="bigLocationMap" @handleMapClick="handleMapClick" :location="location" :modalMap="true" />
    </a-modal>
  </a-row>
</template>

<script>
import { requiredField, minLength, maxLength, domainIsNotValid, emailIsInvalid } from '@/utils/messages';
import { countriesList } from '@/utils/countriesList';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { latLng } from "leaflet";
import LocationMap from '../../../components/LocationMap/LocationMap.vue';

export default {
  name: 'Step1',
  components: {
    LocationMap
  },
  data () {
    return {
      requiredField,
      minLength,
      maxLength,
      domainIsNotValid,
      emailIsInvalid,
      countriesList,
      organizationAddress: '',
      organizationEmail: '',
      addressResults: [],
      addressLoading: false,
      addressData: null,
      locationPicked: false,
      addressResultsEmpty: false,
      time: null,
      organizationsDropdownList: this.$store.getters['organizations/getOrganizations'],
      fetching: false,
      map: {
        zoom: 13,
        center: latLng(50.454442264865214, 30.51689219566667),
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        mapOptions: {
          zoomSnap: 0.5,
          zoomControl: false
        },
        latitude: 50.454442264865214,
        longitude: 30.51689219566667
      },
      location: null,
      showMap: false,
      showBigMap: false,
      emailIsValid: false,
      token: null
    }
  },
  mounted() {
    let modal = document.getElementsByClassName('create-organization-manually')[0];
    if (modal) {
      modal.addEventListener('scroll', this.handleScroll);
    }

    this.token = this.$route.query.token;

    this.$emit('setStateStep');
    if (this.$parent.form.getFieldValue('first_name') || this.$parent.form.getFieldValue('last_name')) {
      this.emailIsValid = true;
    }
    if (this.$parent.form.getFieldValue("location")) {
      this.showMap = true;
      this.location = latLng(this.$parent.form.getFieldValue('latitude'), this.$parent.form.getFieldValue('longitude'));
      this.$nextTick(() => {
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([this.$parent.form.getFieldValue('latitude'), this.$parent.form.getFieldValue('longitude')], 13);
      })
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getUsers']
    },
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    },
    admin() {
      return this.users.find(el => el.role.id !== 3);
    },
    user() {
      return this.users.find(el => el.role.id === 3);
    }
  },
  methods: {
    handleScroll () {
      let openedSelects = document.getElementsByClassName('ant-select ant-select-open');
      let selectDropdowns = document.getElementsByClassName('ant-select-dropdown');

      openedSelects.forEach(element => {
        if (element) {
          element.classList.remove(...['ant-select-open', 'ant-select-focused']);
        }
      })

      selectDropdowns.forEach(element => {
        if (element) {
          element.style.display = 'none';
        }
      })
    },
    fetchOrganizations(value) {
      this.organizationsDropdownList = [];
      this.fetching = true;
      this.$store.dispatch('organizations/GetOrganizationDropdownList', value)
          .then(response => {
            const data = response.data.map(item => ({
              id: item.id,
              name: item.name,
            }));
            this.organizationsDropdownList = data;
            this.fetching = false;
          })
    },
    async validateEmail(_, email, callback) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (!email || !pattern.test(email)) {
        return;
      }

      if (email === this.organizationEmail && this.emailIsValid) return;
      this.organizationEmail = email;

      if (this.token) {
        await this.$store.dispatch('users/GetUsersByEmailWithToken', {token: this.token, email: email});
      } else {
        await this.$store.dispatch('users/GetUsersByEmail', email);
      }

      try {
        if (!this.users.length) {
          this.$parent.form.setFieldsValue({
            first_name: this.$parent.form.getFieldValue("first_name") ? this.$parent.form.getFieldValue("first_name") : '',
            last_name: this.$parent.form.getFieldValue("last_name") ? this.$parent.form.getFieldValue("last_name") : '',
          });
          this.emailIsValid = true;
          callback();
          return;
        }

        if (this.admin || this.user.organization) {
          this.$parent.form.setFieldsValue({
            first_name: this.$parent.form.getFieldValue("first_name") ? this.$parent.form.getFieldValue("first_name") : '',
            last_name: this.$parent.form.getFieldValue("last_name") ? this.$parent.form.getFieldValue("last_name") : '',
          });
          throw new Error(this.$t('messages.userWithThisEmailLinkedToAnotherOrganization'));
        }

        this.$parent.form.setFieldsValue({
          first_name: this.user.first_name,
          last_name: this.user.last_name,
        });

        this.emailIsValid = true;
        callback();
      } catch (e) {
        this.emailIsValid = false;
        callback(e);
      }
    },
    validateDomains(rule, value, callback) {
      if(Boolean(value)){
        try {
          const reg = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/;
          const restrictedDomains = [
            'gmail.com',
            'outlook.com',
            'mail.com',
            'yahoo.com',
            'aol.com',
            'lycos.com'
          ]
          value.forEach(domain => {
            if (!reg.test(domain)) {
              throw new Error(this.$t('messages.domainIsNotValid'));
            }
            if(restrictedDomains.includes(domain)){
              throw new Error(this.$t('messages.domainIsRestricted'));
            }
          })
        } catch (error) {
          callback(error);
          return
        }
      }
      callback()
    },
    validateProfiles(rule, value, callback) {
      if (value) {
        value.forEach(profile => {
          if (profile.length > 12) {
            callback(this.$t('messages.profileIsTooLong'));
          }
        })
        if (value.length > 5) {
          callback(this.$t('messages.profileIsTooLong'));
        } else if (value.length <= 5) {
          callback();
        }
      }
    },
    handleMapClick(e) {
      let {latlng} = e;
      this.location = latLng(latlng.lat, latlng.lng);
      this.$parent.form.setFieldsValue({
        latitude: latlng.lat,
        longitude: latlng.lng
      });
    },
    setLastName(value) {
      //
    },
    setFirstName(value) {
      //
    },
    setLatitude(e) {
      const value = e.target.value;
      if (this.$parent.form.getFieldValue('longitude')) {
        this.location = latLng(value, this.$parent.form.getFieldValue('longitude'));
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([value, this.$parent.form.getFieldValue('longitude')], 13);
      }
    },
    setLongitude(e) {
      const value = e.target.value;
      if (this.$parent.form.getFieldValue('latitude')) {
        this.location = latLng(this.$parent.form.getFieldValue('latitude'), value);
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([this.$parent.form.getFieldValue('latitude'), value], 13);
      }
    },
    openMap() {
      this.showBigMap = true;
      if (this.location.lat && this.location.lng) {
        this.$nextTick(() => {
          this.$refs.bigLocationMap.$refs.bigLocationMap.mapObject.setView([this.location.lat, this.location.lng], 13); 
        })
      }
    },
    closeMap() {
      this.showBigMap = false;
      if (this.location.lat && this.location.lng) {
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([this.location.lat, this.location.lng], 13); 
      }
    },
    searchLocation(e) {
      this.organizationAddress = e;
      if (e.length < 1) {
        this.addressResults = [];
        this.addressLoading = false;
      } else if (e.length > 2) {
            if (this.time) {
                clearTimeout(this.time);
            }
            this.time = setTimeout( () => {
              this.addressLoading = true;
              !this.locationPicked ? this.searchRequest(e) : null
            }, 1000);
      }
    },
    searchRequest(value) {
      const provider = new OpenStreetMapProvider();
      provider.search({ query: value })
          .then(results => {
            this.addressLoading = false
            this.addressResults = results
            this.addressResultsEmpty = false;
            if (results.length === 0) {
              this.addressResultsEmpty = true;
            }
          }).catch(() => { this.addressLoading = false })
    },
    setLocation(value) {
      this.showMap = true;
      const location = value.key.split('|');
      this.location = latLng(location[1], location[0]);
      this.$parent.form.setFieldsValue({
        location: {
          label: location[2]
        },
          latitude: location[1],
          longitude: location[0]
      });
      this.$nextTick(() => {
        this.$refs.locationMap.$refs.locationMap.mapObject.setView([location[1], location[0]], 13);
      })
    },
    chooseAddress(e) {
      const { outerText } = e.target;
      this.locationPicked = true;
      this.$parent.form.setFieldsValue({
        location: outerText,
      });
      this.addressData = address;
      this.addressResults = [];
      this.addressLoading = false;
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}

</script>
