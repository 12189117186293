var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',[_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{staticClass:"one-row large-item lock-mode-field",attrs:{"label":_vm.$t('organizations.step_2.lock_mode')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_lock_mode',
      {valuePropName: 'checked', initialValue:true }]),expression:"['is_lock_mode',\n      {valuePropName: 'checked', initialValue:true }]"}],on:{"change":_vm.lockModeChange}})],1),_c('div',{staticClass:"custom-error has-error"},[(!_vm.lockModeEnabled)?_c('p',{staticClass:"custom-explain ant-form-explain"},[_vm._v(_vm._s(_vm.$t('organizations.step_2.lockModeWarning')))]):_vm._e()]),_c('a-form-item',{attrs:{"label":_vm.$t('organizations.step_2.number_of_picos')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'number_limit',
                {rules: [{required: true, message: _vm.$t('messages.requiredField')}], initialValue: _vm.picoLimit[0]}
          ]),expression:"[\n                'number_limit',\n                {rules: [{required: true, message: $t('messages.requiredField')}], initialValue: picoLimit[0]}\n          ]"}],staticStyle:{"width":"55px"},attrs:{"size":"large","type":"number","min":_vm.picoLimit[0],"max":_vm.picoLimit[_vm.picoLimit.length - 1]}})],1),_c('a-form-item',{staticClass:"one-row large-item",attrs:{"label":_vm.$t('organizations.step_2.limit_holding_time')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_time_limited',
      {valuePropName: 'checked', initialValue:false }]),expression:"['is_time_limited',\n      {valuePropName: 'checked', initialValue:false }]"}],on:{"change":function($event){return _vm.onChange('time_limit.duration')}}})],1),_c('a-form-item',{staticClass:"flex-row",attrs:{"label":_vm.$t('organizations.step_2.holding_time')}},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'time_limit.duration',
                {rules: [{required: _vm.isTimeLimited, message: _vm.$t('messages.requiredField')}]}]),expression:"[\n                'time_limit.duration',\n                {rules: [{required: isTimeLimited, message: $t('messages.requiredField')}]}]"}],staticStyle:{"width":"50px"},attrs:{"disabled":!_vm.isTimeLimited,"size":"large","type":"number","min":0}})],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(["time_limit.unit", {initialValue: _vm.$t('basics.time_units.m')}]),expression:"[`time_limit.unit`, {initialValue: $t('basics.time_units.m')}]"}],staticStyle:{"width":"55px"},attrs:{"disabled":!_vm.isTimeLimited,"size":"large"}},_vm._l((Object.values(_vm.$t('basics.time_units'))),function(item){return _c('a-select-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1)],1),_c('a-col',{staticClass:"second-column",attrs:{"lg":12}},[_c('a-form-item',{staticClass:"one-row large-item",attrs:{"label":_vm.$t('organizations.step_2.limit_working_radius')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_area_limited',
      {valuePropName: 'checked', initialValue:false }]),expression:"['is_area_limited',\n      {valuePropName: 'checked', initialValue:false }]"}],attrs:{"disabled":!_vm.canSwitchAreaToggle},on:{"change":function($event){return _vm.onChange('area_limit.center')}}})],1),_c('a-form-item',{staticClass:"large-item flex-row",attrs:{"label":_vm.$t('organizations.step_2.working_radius')}},[_c('a-form-item',[_c('span',{staticClass:"area-limit-icon"},[_vm._v("±")]),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'area_limit.radius',
                {rules: [{required: true, message: _vm.$t('messages.requiredField')}], initialValue: 1}]),expression:"[\n                'area_limit.radius',\n                {rules: [{required: true, message: $t('messages.requiredField')}], initialValue: 1}]"}],staticStyle:{"width":"60px"},attrs:{"disabled":!_vm.isAreaLimited,"size":"large","type":"number","min":1},on:{"change":_vm.setRadiusValue}})],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(["area_limit.unit",
                {initialValue: _vm.metricUnits[0]}
                ]),expression:"[`area_limit.unit`,\n                {initialValue: metricUnits[0]}\n                ]"}],staticStyle:{"width":"60px"},attrs:{"disabled":!_vm.isAreaLimited,"size":"large"},on:{"change":_vm.setRadiusUnits}},_vm._l((_vm.metricUnits),function(item){return _c('a-select-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1),_c('div',{staticClass:"map-block"},[(_vm.showMap)?_c('p',{staticClass:"ant-col step-2 ant-form-item-label custom-lable"},[_vm._v(_vm._s(_vm.$t('organizations.step_1.pick_radius')))]):_vm._e(),_c('LocationMap',{ref:"radiusMap",attrs:{"radiusMap":true,"circle":_vm.circle,"show":_vm.showMap,"map":_vm.map,"refer":"radiusMap","location":_vm.location},on:{"openMap":_vm.openMap}})],1),_c('a-form-item',{staticClass:"one-row large-item sm-mb",attrs:{"label":_vm.$t('organizations.step_2.gps_tracker')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_gps_on',
      {valuePropName: 'checked', initialValue:false }]),expression:"['is_gps_on',\n      {valuePropName: 'checked', initialValue:false }]"}],on:{"change":_vm.gpsChange}})],1),_c('a-form-item',{staticClass:"one-row large-item sm-mb",attrs:{"label":_vm.$t('organizations.step_2.nfc_reader')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_nfc_reader_on',
      {valuePropName: 'checked', initialValue:true }]),expression:"['is_nfc_reader_on',\n      {valuePropName: 'checked', initialValue:true }]"}]})],1),(_vm.getRole === 1)?_c('a-form-item',{staticClass:"flex-row large-item",staticStyle:{"margin-top":"38px"},attrs:{"label":_vm.$t('organizations.step_2.logs_delay')}},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'log_delay.duration',
                {rules: [
                    {required: true, message: _vm.$t('messages.requiredField')}],
                initialValue: 10}]),expression:"[\n                'log_delay.duration',\n                {rules: [\n                    {required: true, message: $t('messages.requiredField')}],\n                initialValue: 10}]"}],staticStyle:{"width":"50px"},attrs:{"size":"large","type":"number","min":2,"max":60}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'log_delay.unit',
                {rules: [
                    {required: true, message: _vm.$t('messages.requiredField')}],
                initialValue: 's'}]),expression:"[\n                'log_delay.unit',\n                {rules: [\n                    {required: true, message: $t('messages.requiredField')}],\n                initialValue: 's'}]"}],staticClass:"not-editable-input",staticStyle:{"width":"55px"},attrs:{"size":"large"}})],1)],1):_vm._e(),(_vm.getRole === 1)?_c('a-form-item',{staticClass:"flex-row large-item",attrs:{"label":_vm.$t('organizations.step_2.minimum_soc')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'soc_min',
              {rules: [
                  {required: true, message: _vm.$t('messages.requiredField')}],
              initialValue: 0}]),expression:"[\n              'soc_min',\n              {rules: [\n                  {required: true, message: $t('messages.requiredField')}],\n              initialValue: 0}]"}],attrs:{"size":"large","type":"number","min":0,"max":50}})],1):_vm._e()],1),_c('a-modal',{attrs:{"width":1016,"wrapClassName":"map-modal","footer":false},on:{"cancel":_vm.closeMap},model:{value:(_vm.showBigMap),callback:function ($$v) {_vm.showBigMap=$$v},expression:"showBigMap"}},[_c('LocationMap',{ref:"bigRadiusMap",attrs:{"radiusMap":true,"circle":_vm.circle,"map":_vm.map,"refer":"bigRadiusMap","location":_vm.location,"modalMap":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }