<template>
  <a-row>
    <a-col :lg="12" class="small-column">
      <div class="column-title">{{ $t('organizations.step_3.enable_inputs') }}</div>
      <a-form-item class="usbc-field one-row sm-mb" label="USB-C 100W">
        <a-switch @change="onUsbcInputChange" v-decorator="['is_input_usb_c_100w',
        {valuePropName: 'checked', initialValue:false }]" />
      </a-form-item>
      <div class="custom-error has-error">
        <p v-if="isUSBcOn" class="custom-explain-step-3 ant-form-explain">{{ $t('organizations.step_3.usbcWarning') }}</p>
      </div>

      <a-form-item :label="$t('organizations.step_1.language')">
        <a-select
            size="large"
            :showArrow="true"
            :placeholder="$t('organizations.select_from_list')"
            :filter-option="false"
            v-decorator="['language_id', {initialValue: initialLanguageValue}]">
          <a-select-option v-for="item in languagesList" :value="item.id" :key="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label="$t('basics.logo')">
        <a-input
            size="large"
            :placeholder="$t('organizations.enter_logo_url')"
            v-decorator="['logo']"
        />
      </a-form-item>
    </a-col>
    <a-col :lg="12" class="second-column">
      <div class="column-title small-width">{{ $t('organizations.step_3.charge_mode_title') }}</div>
      <a-form-item class="small-width">
        <a-radio-group class="charge-mode ac-mode small-width"
                       v-decorator="['charge_mode', {initialValue: 1}]">
          <a-radio-button :value="0">
            {{ $t('organizations.step_3.charge_mode.highest') }}
          </a-radio-button>
          <a-radio-button :value="1">
            {{ $t('organizations.step_3.charge_mode.lowest') }}
          </a-radio-button>
          <a-radio-button :value="2">
            {{ $t('organizations.step_3.charge_mode.eco') }}
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <div class="column-title small-width">{{ $t('organizations.step_3.ac_mode') }}</div>
      <a-form-item>
        <a-radio-group class="small-width charge-mode ac-mode" v-decorator="['ac_mode', {initialValue: 0}]">
          <a-radio-button :value="0">
            220VAC~50Hz
          </a-radio-button>
          <a-radio-button :value="1">
            300VAC~3Hz
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item class="one-row sm-mb" :label="$t('organizations.step_3.statistic_section')">
        <a-switch v-decorator="['statistic',
        {valuePropName: 'checked', initialValue:false }]" />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'Step3',
  data() {
    return {
      isUSBcOn: false
    }
  },
  mounted() {
    this.$emit('setStateStep');
    this.$store.dispatch('languages/GetLanguagesList');
  },
  computed: {
    languagesList() {
      return this.$store.getters['languages/getLanguagesList'];
    },
    initialLanguageValue() {
      return this.languagesList.length ? this.languagesList.find(el => el.name.toLowerCase() === 'french').id : null;
    }
  },
  methods: {
    onUsbcInputChange(e) {
      this.isUSBcOn = e;
    }
  },
}
</script>

<style lang="less">
.charge-mode {
  display: flex;

  > label:first-of-type {
    margin-right: 20px;
  }
  > label:nth-of-type(3n) {
    margin-left: 20px;
  }

  .ant-radio-button-wrapper {
    height: auto;
    width: 50%;
    border-radius: 8px;
    padding: 13px 10px;
    display: flex;
    align-items: center;
    line-height: 1.4;
    background: transparent;
    border: 1px solid #DDE1E5;
    box-shadow: none;

    &:not(:first-child)::before {
      content: none;
    }

    &-checked {
      &:not(.ant-radio-button-wrapper-disabled) {
        border: 2px solid #F6CE54;

        &:first-child {
          border: 2px solid #F6CE54;
        }

        &:hover {
          box-shadow: none;
        }

        &:focus-within {
          outline: none;
        }
      }
    }
  }
}
.ac-mode {
  > label:first-of-type {
    margin-left: 10px;
  }
}

</style>
<style lang="less">
  .custom-error {
    position: relative;
    width: auto;
  }
  .custom-explain-step-3 {
    position: absolute;
    top: -80px;
  }
.create-organization-form {
  .ant-form-item {
    &.usbc-field {
      margin-bottom: 75px;
    }
  }
}
</style>